import { css } from 'styled-components';

export const theme = {
	appGutter: `5vw`,
	colorAccent: '#f33f5d',
	colorPrimary: '#141414',
	colorPassive: '#989898',
	logoSize: '40px',
	navbarHeight: '80px',
	navbarArrowSize: '25px',
	baseTransition: '.2s',
};

export const styledTitle = css`
	color: white;
	font-weight: 700;
	text-transform: uppercase;
`;

export const styledClient = css`
	${styledTitle} color: ${(props) => props.theme.colorAccent};
`;
