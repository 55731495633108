// actions
export const UPDATE_ACTIVE_CAROUSEL_ITEM = 'UPDATE_ACTIVE_CAROUSEL_ITEM';
export const UPDATE_ENLARGED_CAROUSEL_ITEM = 'UPDATE_ENLARGED_ITEM';
export const RESET_CAROUSEL = 'RESET_CAROUSEL';
export const UPDATE_ENLARGED_OFFSET = 'UPDATE_ENLARGED_OFFSET';

// init state
const initialState = {
	activeItemId: 0,
	// items: [],
	enlargedItemId: undefined,
	enlargedOffset: 0,
};

// actions creators
export const updateEnlargedItem = (id: number, offset = 0) => ({
	type: UPDATE_ENLARGED_CAROUSEL_ITEM,
	enlargedItemId: id,
	enlargedOffset: offset,
});

export const updateActiveItem = (id: number) => ({
	type: UPDATE_ACTIVE_CAROUSEL_ITEM,
	activeItemId: id,
});

export const resetCarousel = () => ({
	type: RESET_CAROUSEL,
});

export const updateEnlargedOffset = (offset: number) => ({
	type: UPDATE_ENLARGED_OFFSET,
	offset,
});

// reducers
export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_ACTIVE_CAROUSEL_ITEM:
			return {
				...state,
				activeItemId: action.activeItemId,
			};
		case UPDATE_ENLARGED_CAROUSEL_ITEM:
			return {
				...state,
				enlargedItemId: action.enlargedItemId,
				enlargedOffset: action.enlargedOffset,
			};
		case UPDATE_ENLARGED_OFFSET:
			return {
				...state,
				enlargedOffset: action.offset,
			};
		case RESET_CAROUSEL:
			return initialState;
		default:
			return state;
	}
};
