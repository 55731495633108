import { animated } from 'react-spring';
import styled from 'styled-components';
import ArrowLeft from '../Carousel/CarouselControls/icons/arrow-left.svg';

export const StyledNavbarLogo = styled.span`
	display: block;
	height: 100%;
	position: relative;
`;

export const ArrowContainer = styled(animated.span)`
	align-items: center;
	display: flex;
	height: 100%;
	position: absolute;
	left: 0;
	width: ${(props) => props.theme.navbarArrowSize};
`;

export const Arrow = styled(ArrowLeft)`
	fill: white;
	height: ${(props) => props.theme.navbarArrowSize};
`;

export const Logo = styled(animated.span)`
	display: block;
	height: 100%;
	svg {
		height: ${(props) => props.theme.logoSize};
		width: ${(props) => props.theme.logoSize};
	}
`;
