import React from 'react';
import { NavbarItem, NavbarLink } from './styled';

export default ({ children, link, title }) => {
	return (
		<NavbarItem>
			<NavbarLink to={link} activeClassName="active" title={title}>
				{children}
			</NavbarLink>
		</NavbarItem>
	);
};
