import React from 'react';
import { ThemeProvider } from 'styled-components';

import './layout.scss';
import { theme } from './theme';
import Navbar from '../Navbar';
import NavbarItem from '../NavbarItem';

export default ({ children, location }) => (
	<ThemeProvider theme={theme}>
		<main className="layout">
			<Navbar location={location}>
				<NavbarItem link="/contact" title="Contact page">
					contact
				</NavbarItem>
			</Navbar>
			{children}
		</main>
	</ThemeProvider>
);
