import React from 'react';
import { Location } from '@reach/router';
import { useSpring } from 'react-spring';
import { StyledNavbarLogo, Arrow, Logo, ArrowContainer } from './styled';
import { theme } from '../Layout/theme';

const NavbarLogo = (props) => {
	const location = props.location;
	const showPrevious = location.pathname !== '/';

	const spring = useSpring({
		opacity: showPrevious ? 1 : 0,
		arrowX: showPrevious
			? `translateX(0px)`
			: `translateX(-${theme.navbarArrowSize})`,
		logoX: showPrevious
			? `translateX(${theme.navbarArrowSize})`
			: `translateX(0px)`,
	});

	return (
		<StyledNavbarLogo>
			<ArrowContainer
				style={{ opacity: spring.opacity, transform: spring.arrowX }}
			>
				<Arrow />
			</ArrowContainer>
			<Logo style={{ transform: spring.logoX }}>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 193.061 144.481"
					width="193.061"
					height="144.481"
				>
					<g>
						<polygon points="1.985 2.477 14.121 5.228 14.121 139.253 1.985 142.004 1.985 2.477" />
						<polygon points="65.449 2.316 75.103 5.079 14.121 5.228 1.985 2.477 65.449 2.316" />
						<polygon
							points="65.449 2.316 75.103 5.079 75.103 74.494 65.449 74.587 65.449 2.316"
							fill="#ae2d43"
						/>
						<polygon points="67.435 0.315 77.012 3.157 77.012 69.978 67.435 69.885 67.435 0.315" />
						<polygon points="1.985 142.004 14.121 139.253 84.277 139.424 74.997 142.189 1.985 142.004" />
						<polygon
							points="94.319 123.843 102.841 121.802 84.277 139.424 74.997 142.189 94.319 123.843"
							fill="#7c2030"
						/>
						<polygon points="94.283 126.633 102.806 124.481 85.034 141.343 75.785 144.188 94.283 126.633" />
						<polygon points="94.301 46.628 102.823 47.641 75.103 74.494 65.449 74.587 94.301 46.628" />
						<polygon points="94.319 123.843 102.841 121.802 120.455 139.512 112.653 142.285 94.319 123.843" />
						<polygon
							points="94.301 46.628 102.824 47.641 129.641 73.538 122.216 73.592 94.301 46.628"
							fill="#af2d43"
						/>
						<polygon points="94.301 43.847 102.823 44.97 127.726 69.014 120.222 68.88 94.301 43.847" />
						<polygon points="122.216 2.172 129.641 4.947 129.641 73.538 122.216 73.592 122.216 2.172" />
						<polygon points="112.653 142.285 120.455 139.512 191.136 139.684 186.235 142.471 112.653 142.285" />
						<polygon points="186.235 2.01 191.136 4.797 129.641 4.947 122.216 2.172 186.235 2.01" />
						<polygon
							points="186.235 2.01 191.137 4.797 191.137 139.684 186.235 142.471 186.235 2.01"
							fill="#9c293c"
						/>
						<polygon
							points="186.235 2.01 186.235 142.471 112.653 142.285 94.319 123.843 74.997 142.189 1.986 142.004 1.986 2.477 65.449 2.316 65.449 74.587 94.301 46.628 122.216 73.592 122.216 2.172 186.235 2.01"
							fill="#d03650"
						/>
						<polygon points="188.238 0 193.061 2.867 193.061 141.613 188.238 144.481 188.238 0" />
						<path
							className="navbar__logo-outline"
							d="M663.424,440.208l68.016-.178V584.511l-76.411-.2-17.545-17.648-18.5,17.554-75.784-.2v-143.5l67.435-.176v69.57L637.5,483.878l25.922,25.033ZM729.437,582.5V442.04l-64.02.163v71.419L637.5,486.658l-28.851,27.959V442.346l-63.464.161V582.035l73.012.185,19.321-18.346,18.335,18.441,73.582.186"
							transform="translate(-543.202 -440.03)"
						/>
					</g>
				</svg>
			</Logo>
		</StyledNavbarLogo>
	);
};

export default () => (
	<Location>
		{(locationProps) => <NavbarLogo location={locationProps.location} />}
	</Location>
);
