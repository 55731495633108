import styled from 'styled-components';

export const Navbar = styled.nav`
	align-items: center;
	display: flex;
	justify-content: space-between;
	left: 0;
	padding: 40px ${(props) => props.theme.appGutter} 0;
	position: fixed;
	opacity: ${(props) => (props.active ? 1 : 0)};
	top: 0;
	transform: ${(props) =>
		props.active ? `translateY(0%)` : `translateY(-25%)`};
	transition: all .2s ease;
	width: 100%;
	z-index: 2;
	@media screen and (max-width: 600px) {
		padding: 20px ${(props) => props.theme.appGutter};
	}
`;

export const NavbarItems = styled.span`
	display: flex;
	justify-content: space-between;
`;
