import { Link } from 'gatsby';
import styled from 'styled-components';

export const NavbarLink = styled(Link)`
	color: inherit;
	font-size: 14px;
	position: relative;
	text-decoration: none;
	transition: all .1s ease-out;
	&:after {
		content: '';
		bottom: -4px;
		opacity: 0;
		border-bottom: 2px dotted white;
		left: 0;
		position: absolute;
		transition: all .1s ease-out;
		width: 100%;
	}
	&.active {
		color: ${(props) => props.theme.colorAccent};
		&:after {
			border-color:  ${(props) => props.theme.colorAccent};
			opacity: 1;
		}
	}
`;

export const NavbarItem = styled.span`
	font-weight: 700;
	letter-spacing: 1px;
	text-transform: uppercase;

	&:hover {
		${NavbarLink}:after {
			opacity: 1;
		}
	}
`;
