import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import Layout from './src/components/Layout/layout';
import rootReducer from './src/state/index';

export default ({ element, props }) => {
	const store = createStore(rootReducer);
	// store.subscribe(() => console.log(store.getState()));

	return (
		<Provider store={store}>
			<Layout {...props}>{element}</Layout>
		</Provider>
	);
};
