import('./index.css');
import('./src/styles/app.scss');
import 'typeface-ibm-plex-sans';

import React from 'react';
import pageElementWrapper from './wrapPageElement';

export const onClientEntry = () => {
	window.addEventListener('load', () => {
		document.body.classList.remove('no-js');
	});
};

export const wrapPageElement = pageElementWrapper;
