import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { connect, useSelector } from 'react-redux';
// import * as easings from 'd3-ease';

import NavbarLogo from '../NavbarLogo';
import { Navbar as StyledNavbar, NavbarItems } from './styled';
import { useSpring } from 'react-spring';
// import { HEADER_ANIMATION_STATUS } from '../../state/header';
// import { useSpring, config } from 'react-spring';

export default function Navbar({ children, location }) {
	// const { completed } = useSelector((state) => state.header);
	// const shouldAnimate =
	// 	typeof window !== `undefined` ? location.pathname === '/' : false;
	// const [showNavbar, setShowNavbar] = useState(!shouldAnimate);
	// const { status } = useSelector((state) => state.header);

	// useEffect(() => {
	// 	const handleScroll = (e) => {
	// 		const show = window.scrollY >= document.documentElement.clientHeight / 2;

	// 		setShowNavbar(show);
	// 	};
	// 	window.addEventListener('scroll', handleScroll);
	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll);
	// 	};
	// }, []);

	// console.log(showNavbar, shouldAnimate);
	return (
		<StyledNavbar active>
			<Link to="/" title="Home">
				<NavbarLogo />
			</Link>
			<NavbarItems>{children}</NavbarItems>
		</StyledNavbar>
	);
}
