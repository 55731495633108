// action
export const UPDATE_HEADER_STATE = 'UPDATE_HEADER_STATE';

// // export const HEADER_ANIMATION_STATE = 'HEADER_ANIMATION_STATE';
// export enum HEADER_ANIMATION_STATUS {
// 	RUNNING = 'RUNNING',
// 	FINISHED = 'FINISHED',
// }

const initialState = {
	completed: false,
};

// action creator
export const toggleHeaderState = (completed: boolean) => ({
	type: UPDATE_HEADER_STATE,
	completed,
});

// reducer
export default (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_HEADER_STATE:
			return { ...state, completed: action.completed };
		default:
			return state;
	}
};
